.logo-container {
    width: 130px;
    padding: 10px;
}

.logo-container img {
    width: 100%;
}

.header-link {
    color: #3D464D;
    text-decoration: none;
}

.header-link.active {
    font-weight: bold;
}

.header-link-container {
    padding-top: 15px;
}

.header-link-active {
    color: #0091CD;
    border-bottom: solid #0091CD 2px;
}

.nav-link-container {
    text-align: center;
    padding: 0px!important;
    /* height: 44px; */
    vertical-align: middle;
}

.nav-link {
    text-decoration: none;
    text-transform: uppercase;
    margin: auto!important;
    padding: 12px 10px!important;
    display: block;
    font-size: 14px;
}

.nav-link.active {
    background-color: #E9E9ED;
}

.nav-link:hover {
    color: #C5C7C8;
}

#boton-bars {
    background-color: #3D464D;
    color: #ffffff;
}

.logo-mobile-container {
    padding: 10px;
}

.logo-mobile-container img {
    width: 120px;
    margin: auto;
}

.header-mobile-link {
    color: #3D464D;
    text-decoration: none;
}

.header-link-mobile-container {
    padding-top: 15px;
}

/* MOBILE */

@media only screen and (max-width: 766px) {}

@media only screen and (max-width: 600px) {}

@media only screen and (min-width: 601px) and (max-width: 767px) {}

@media only screen and (min-width: 768px) and (max-width: 992px) {}

@media only screen and (min-width: 993px) and (max-width: 1279px) {}

@media only screen and (min-width: 1280px) {}