#nav-side {
  z-index: 3;
  width: 260px;
  min-height: 100vh;
  position: fixed;
}

/* MOBILE */
@media only screen and (max-width: 766px) {
}

@media only screen and (max-width: 600px) {
}

@media only screen and (min-width: 601px) and (max-width: 767px) {
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
}

@media only screen and (min-width: 993px) and (max-width: 1279px) {
}

@media only screen and (min-width: 1280px) {
}
