.tags {
    list-style: none;
    margin: 0;
    overflow: hidden;
    padding: 0;
}

.tags li {
    float: left;
}

.tag {
    /* background: #eee; */
    border-radius: 3px;
    /* color: #999; */
    display: inline-block;
	width: 185px;
    height: 26px;
    line-height: 26px;
    padding: 0 20px 1px 20px;
    position: relative;
    text-decoration: none;
	transition: color 0.2s;
    -webkit-transition: color 0.2s;
	text-align: center;
}

/*
.tag::before {
    background: #fff;
    border-radius: 10px;
    box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
    content: '';
    height: 6px;
    left: 10px;
    position: absolute;
    width: 6px;
    top: 10px;
}
*/

/* .tag::after {
    background: #fff;
    border-bottom: 13px solid transparent;
    border-left: 10px solid #eee;
    border-top: 13px solid transparent;
    content: '';
    position: absolute;
    right: 0;
    top: 0;
} */
