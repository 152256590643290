.buttons-col {
    width: 150px;
}

.buttons-col button {
    margin-left: 2px;
}

.sliding-panel-container.active {
    box-sizing: border-box;
    z-index: 13000;
}

.sliding-panel-container {
    box-sizing: border-box;
}

.crop-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 80px;
}

.crop-controls {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 50%;
    transform: translateX(-50%);
    height: 80px;
    display: flex;
    align-items: center;
}

.crop-slider {
    padding: 22px 0px;
}

.tabla tbody td {
    vertical-align: middle!important;
	color: gray;
}

/* MOBILE */

@media only screen and (max-width: 766px) {}

@media only screen and (max-width: 600px) {}

@media only screen and (min-width: 601px) and (max-width: 767px) {}

@media only screen and (min-width: 768px) and (max-width: 992px) {}

@media only screen and (min-width: 993px) and (max-width: 1279px) {}

@media only screen and (min-width: 1280px) {}