img {
	width: 200px;
}

.login-box {
	width: 100%;
	max-width: 360px;
}

.login-box>div {
	padding: 40px;
}

.carousel-back-btn {
	position: absolute;
	top: 45%;
	left: 10px;
	background-color: transparent;
	border: 0;
	font-size: 40px;
	color: #cccccc;
}

.carousel-back-btn:focus {
	border: none;
	outline: none;
}

.carousel-next-btn {
	position: absolute;
	top: 45%;
	right: 10px;
	background-color: transparent;
	border: 0;
	font-size: 40px;
	color: #cccccc;
}

.carousel-next-btn:focus {
	border: none;
	outline: none;
}

.info-item:hover {
	background-color: #F3F3F3;
	cursor: pointer;
}

.finalizado {
	background-color: #e3eff3;
}

/* MOBILE */
@media only screen and (max-width: 766px) {}

@media only screen and (max-width: 600px) {
	.login-box>div {
		padding: 16px;
	}
}

@media only screen and (min-width: 601px) and (max-width: 767px) {}

@media only screen and (min-width: 768px) and (max-width: 992px) {}

@media only screen and (min-width: 993px) and (max-width: 1279px) {}

@media only screen and (min-width: 1280px) {}