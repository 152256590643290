body {
    margin: 0;
}

html {
    overflow-x: hidden;
    height: 100%;
}

.content-min-height {
    min-height: calc(100vh - 324px);
    padding-bottom: 60px;
}

.my-content {
    width: 100%;
    max-width: 1120px;
    margin: auto;
    padding-left: 16px;
    padding-right: 16px;
}

.cursor-pointer:hover {
    cursor: pointer !important;
}

.visibility-hidden {
    visibility: hidden;
}

.visibility-visible {
    visibility: visible;
}

.background-cover {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
}

.xhover-pointer:hover {
    cursor: pointer;
}

.xlabel {
    margin-bottom: 0px;
    color: gray;
    font-weight: bold;
    font-size: 14px;
}

.xbold {
    font-weight: bold;
}

.bs-bbox {
    box-sizing: border-box;
}

.full-size {
    width: 100%;
    height: 100vh;
}

.paginator-li {
    padding: 0px!important;
}

.paginator-li a {
    padding: 8px 16px;
    display: inline-block;
}

.paginator-container>ul {
    padding-inline-start: 0px!important;
}

.margin-2 {
    margin: 2px!important;
}

.text-decoration-none {
    text-decoration: none!important;
}

.ellipsis {
    text-overflow: ellipsis;
    /* Required for text-overflow to do anything */
    white-space: nowrap;
    overflow: hidden;
}

/* MOBILE */

@media only screen and (max-width: 766px) {}

@media only screen and (max-width: 600px) {}

@media only screen and (min-width: 601px) and (max-width: 767px) {}

@media only screen and (min-width: 768px) and (max-width: 992px) {}

@media only screen and (min-width: 993px) and (max-width: 1279px) {}

@media only screen and (min-width: 1280px) {}