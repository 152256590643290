.w3-theme-l5 {color:#000 !important; background-color:#fef1f2 !important}
.w3-theme-l4 {color:#000 !important; background-color:#fbd2d3 !important}
.w3-theme-l3 {color:#000 !important; background-color:#f8a4a7 !important}
.w3-theme-l2 {color:#fff !important; background-color:#f4777b !important}
.w3-theme-l1 {color:#fff !important; background-color:#f04a4f !important}
.w3-theme-d1 {color:#fff !important; background-color:#dd1219 !important}
.w3-theme-d2 {color:#fff !important; background-color:#c41016 !important}
.w3-theme-d3 {color:#fff !important; background-color:#ac0e13 !important}
.w3-theme-d4 {color:#fff !important; background-color:#930c10 !important}
.w3-theme-d5 {color:#fff !important; background-color:#7b0a0e !important}

.w3-theme-light {color:#000 !important; background-color:#fef1f2 !important}
.w3-theme-dark {color:#fff !important; background-color:#7b0a0e !important}
.w3-theme-action {color:#fff !important; background-color:#7b0a0e !important}

.w3-theme {color:#fff !important; background-color:#ed1c24 !important}
.w3-text-theme {color:#ed1c24 !important}
.w3-border-theme {border-color:#ed1c24 !important}

.w3-hover-theme:hover {color:#fff !important; background-color:#ed1c24 !important}
.w3-hover-text-theme:hover {color:#ed1c24 !important}
.w3-hover-border-theme:hover {border-color:#ed1c24 !important}

.w3-hover-theme-l5:hover {color:#000 !important; background-color:#fef1f2 !important}
.w3-hover-theme-l4:hover {color:#000 !important; background-color:#fbd2d3 !important}
.w3-hover-theme-l3:hover {color:#000 !important; background-color:#f8a4a7 !important}
.w3-hover-theme-l2:hover {color:#fff !important; background-color:#f4777b !important}
.w3-hover-theme-l1:hover {color:#fff !important; background-color:#f04a4f !important}
.w3-hover-theme-d1:hover {color:#fff !important; background-color:#dd1219 !important}
.w3-hover-theme-d2:hover {color:#fff !important; background-color:#c41016 !important}
.w3-hover-theme-d3:hover {color:#fff !important; background-color:#ac0e13 !important}
.w3-hover-theme-d4:hover {color:#fff !important; background-color:#930c10 !important}
.w3-hover-theme-d5:hover {color:#fff !important; background-color:#7b0a0e !important}

.w3-color-theme-l5 {color:#fef1f2 !important}
.w3-color-theme-l4 {coloR:#fbd2d3 !important}
.w3-color-theme-l3 {color:#f8a4a7 !important}
.w3-color-theme-l2 {color:#f4777b !important}
.w3-color-theme-l1 {color:#f04a4f !important}
.w3-color-theme {color:#ed1c24 !important}
.w3-color-theme-d1 {color:#dd1219 !important}
.w3-color-theme-d2 {color:#c41016 !important}
.w3-color-theme-d3 {color:#ac0e13 !important}
.w3-color-theme-d4 {color:#930c10 !important}
.w3-color-theme-d5 {color:#7b0a0e !important}