/*background*/
.background-primary {
    background-color: #007BFF !important;
}
.background-secondary {
    background-color: #6C757D !important;
}
.background-success {
    background-color: #28A745 !important;
}
.background-danger {
    background-color: #DC3545 !important;
}
.background-warning {
    background-color: #FFC107 !important;
}
.background-info {
    background-color: #17A2B8 !important;
}
.background-light {
    background-color: #F8F9FA !important;
}
.background-dark {
    background-color: #343A40 !important;
}
/*background and color*/
.x-primary {
    color: #FFFFFF !important;
    background-color: #007BFF !important;
}
.x-secondary {
    color: #FFFFFF !important;
    background-color: #6C757D !important;
}
.x-success {
    color: #FFFFFF !important;
    background-color: #28A745 !important;
}
.x-danger {
    color: #FFFFFF !important;
    background-color: #DC3545 !important;
}
.x-warning {
    color: #FFFFFF !important;
    background-color: #FFC107 !important;
}
.x-info {
    color: #FFFFFF !important;
    background-color: #17A2B8 !important;
}
.x-light {
    color: #212529 !important;
    background-color: #F8F9FA !important;
}
.x-dark {
    color: #FFFFFF !important;
    background-color: #343A40 !important;
}
/*color*/
.color-primary {
    color: #007BFF !important;
}
.color-secondary {
    color: #6C757D !important;
}
.color-success {
    color: #28A745 !important;
}
.color-danger {
    color: #DC3545 !important;
}
.color-warning {
    color: #FFC107 !important;
}
.color-info {
    color: #17A2B8 !important;
}
.color-light {
    color: #F8F9FA !important;
}
.color-dark {
    color: #343A40 !important;
}